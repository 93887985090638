import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import AuthorImage from "../../src/components/author-image";
import SocialLink from "../../src/components/social-link";
import { rhythm } from "../../src/utils/typography";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div style={{
      marginRight: rhythm(1 / 2),
      float: "left"
    }}>
	<AuthorImage mdxType="AuthorImage" />
    </div>
    <p>{`Hi! I'm Itai, a software engineer from Israel. I develop full-stack apps & tools, maintain some OSS projects, and enjoy life. This website contains details on some of my projects, as well as my thoughts and experiences from working as a software engineer.`}</p>
    <p>{`If you'd like to contact me, feel free to do so via `}<SocialLink service="email" mdxType="SocialLink">{`email`}</SocialLink>{` or `}<SocialLink service="twitter" mdxType="SocialLink">{`Twitter`}</SocialLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      